import * as React from 'react';
import { Container, Row, Col, Button, Table, Spinner, Form, InputGroup, FormControl, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Pagination from '@material-ui/lab/Pagination';
import { FaCopy, FaEnvelopeOpenText, FaTimesCircle } from 'react-icons/fa'
import { ApplicationState } from '../../store';
import { formatRFC3339TpDMY, findColurLevel } from '../modules/Common'

import * as GetLogStore from '../../store/log/Logger'
import * as GetLogModel from '../../models/Logger'
import { yellow } from '@material-ui/core/colors';
import { reverse } from 'dns';
type store = GetLogModel.GetLoggerState & typeof GetLogStore.actionCreators

class LogTable extends React.Component<any, { isready: boolean, pageSize: number, pageBtn: any, keySearchTb: any, checkPageSize: boolean, SearchLoggerReq: GetLogModel.SearchLoggerReq, isShowMessageModal: boolean, selectMessageID: any, messageMode: string, formData: Array<GetLogModel.LoggerModel> }, store>{

    constructor(props: any) {
        super(props);
        this.state = {
            isready: true,
            pageSize: 25,
            pageBtn: [''],
            checkPageSize: true,
            SearchLoggerReq: {
                function_name: '',
                message: '',
                output_message: '',
                created_by: '',
                timestamp: '',
            },
            isShowMessageModal: false,
            selectMessageID: null,
            messageMode: "",
            formData: [],
            keySearchTb: ""
        }
        this.handleSearch = this.handleSearch.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeSearchInput = this.handleChangeSearchInput.bind(this)
        this.hilightText = this.hilightText.bind(this)
    }

    componentDidMount() {
        this.props.requestGetLogger(this.state.isready, "GET", "", "", "", "", "", 1, this.state.pageSize);
    }

    handlePageSize(e: any) {
        //console.log("5555")
        //console.log(e.target.value)
        //const { SearchLoggerReq } = this.state;
        const { searchLoggerReq } = this.props;
        //console.log(SearchLoggerReq)
        var parse = parseInt(e.target.value)
        this.setState({
            pageSize: parse,
            isready: true,
            checkPageSize: true

        });
        this.props.requestGetLogger(true, "GET", searchLoggerReq.function_name, searchLoggerReq.message, searchLoggerReq.output_message, searchLoggerReq.created_by, searchLoggerReq.timestamp, 1, parse)
    }

    handleChangePage = (event: any, size: any) => {
        //const { SearchLoggerReq } = this.state;
        const { searchLoggerReq } = this.props;
        //console.log(SearchLoggerReq)
        this.setState({
            isready: true,
        })
        this.props.requestGetLogger(true, "GET", searchLoggerReq.function_name, searchLoggerReq.message, searchLoggerReq.output_message, searchLoggerReq.created_by, searchLoggerReq.timestamp, size, this.state.pageSize)
        //this.props.requestGetDmsConfig(true, "GET", "", size, this.state.pageSize)
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { checkkey, GetAPI } = this.props
        if (checkkey == "Reset" && checkkey != undefined) {
            console.log("Reset")
            console.log(checkkey)
            this.setState({
                formData: [],
            })
            this.handleSearch()
        }

        if (GetAPI.statusLogger == 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] != undefined && this.state.checkPageSize) {
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(GetAPI.responseLogger['total_count'] / this.state.pageSize)
            //console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                //data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr,
                checkPageSize: false
            })
        }

        if (GetAPI.statusLogger == 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] != undefined && this.state.isready) {
            console.log('Change datatable')
            console.log(GetAPI.responseLogger['result_list'])
            console.log(dataArr)
            this.setState({
                formData: GetAPI.responseLogger['result_list'],
                isready: false,
            })
        }
        else if ((GetAPI.statusLogger == 200 || GetAPI.statusLogger != 200) && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] == undefined && this.state.isready) {
            this.setState({
                formData: [],
                isready: false,
            })
        }
    }

    handleSearch() {
        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        const { searchLoggerReq } = this.props;
        //console.log(searchLoggerReq)
        //console.log("handleSearch 204")
        const { isready, pageSize } = this.state
        this.props.requestGetLogger(true, "GET", searchLoggerReq.function_name, searchLoggerReq.message, searchLoggerReq.output_message, searchLoggerReq.created_by, searchLoggerReq.timestamp, 1, pageSize,)
        this.props.callbackkey()
        this.setState({
            SearchLoggerReq: this.props.SearchLoggerReq,
            checkPageSize: true,
            isready: true
        })
    }

    handleCloseMessageModal = (id: any, key: string) => {
        if (this.state.isShowMessageModal) {
            this.setState({
                isShowMessageModal: false,
                selectMessageID: null,
                messageMode: ""
            })
        } else {
            this.setState({
                isShowMessageModal: true,
                selectMessageID: id,
                messageMode: key
            })
        }
    }

    handleChangeSearchInput(e: any) {
        console.log(e.target.value)
        var arr: any = []
        var key: any = e.target.value
        const { GetAPI } = this.props;
        if (GetAPI.statusLogger == 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] != undefined) {
            //console.log(GetAPI.responseLogger['result_list'])
            for (var i = 0; i < GetAPI.responseLogger['result_list'].length; i++) {
                var data: any = GetAPI.responseLogger['result_list'][i]
                var id: string = data.id.toLowerCase()
                var function_name: string = data.function_name.toLowerCase()
                var message: string = data.message.toLowerCase()
                var output_message: string = data.output_message.toLowerCase()
                var level: string = data.level.toLowerCase()
                var timestamp: string = formatRFC3339TpDMY(String(data.timestamp)).toLowerCase()
                var created_by: string = data.created_by.toLowerCase()
                var exception: string = data.exception.toLowerCase()
                var system_id: string = data.system_id.toLowerCase()
                var sub_system_id: string = data.sub_system_id.toLowerCase()

                var check_id: number = id.indexOf(key.toLowerCase())
                var check_function_name: number = function_name.indexOf(key.toLowerCase())
                var check_message: number = message.indexOf(key.toLowerCase())
                var check_output_message: number = output_message.indexOf(key.toLowerCase())
                var check_level: number = level.indexOf(key.toLowerCase())
                var check_timestamp: number = timestamp.indexOf(key.toLowerCase())
                var check_created_by: number = created_by.indexOf(key.toLowerCase())
                var check_exception: number = exception.indexOf(key.toLowerCase())
                var check_system_id: number = system_id.indexOf(key.toLowerCase())
                var check_sub_system_id: number = sub_system_id.indexOf(key.toLowerCase())
                if (check_id > -1 || check_function_name > -1 || check_message > -1 || check_output_message > -1 || check_level > -1 || check_timestamp > -1 || check_created_by > -1 || check_exception > -1 || check_system_id > -1 || check_sub_system_id > -1) {
                    arr.push(data)
                }
            }
            this.setState({
                formData: arr,
                keySearchTb: key
            })
        }
    }

    hilightText(val: any) {
        //console.log('val')
        //console.log(val)
        const { keySearchTb } = this.state;
        if (keySearchTb != "" && val != null && val != undefined && val != "") {
            var check: number = (val.toLowerCase()).indexOf(keySearchTb.toLowerCase())
            if (check > -1) {
                var str = val.substring(0, check) + '<span style="background-color: yellow;">' + val.substring(check, check + keySearchTb.length) + '</span>' + val.substring(check + keySearchTb.length)
                //console.log('str')
                //console.log(str)
                return (
                    <span>{val.substring(0, check)}<span style={{ backgroundColor: 'yellow' }}>{val.substring(check, check + keySearchTb.length)}</span>{val.substring(check + keySearchTb.length)}</span>
                )
            }
            else {
                return val;
            }
        }
        else {
            return val;
        }
    }

    render() {
        const { GetAPI } = this.props
        console.log(this.state.formData)
        return (
            <div>
                <Row className='mt-1'>
                    <Col xs={6}>
                        <div className='d-flex justify-content-start my-2 my-lg-0'>
                            <Form inline>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    Show
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                    size="sm"
                                    value={this.state.pageSize}
                                    onChange={this.handlePageSize}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    entries
                                </Form.Label>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <Form.Group className="mb-3 flex-row-reverse" as={Row}>
                            <Col sm="5">
                                <div className='d-flex justify-content-end my-2 my-lg-0'>
                                    <Pagination count={this.state.pageBtn.length > 0 ? this.state.pageBtn.length : undefined} onChange={this.handleChangePage} shape="rounded" siblingCount={0} defaultPage={1} showFirstButton showLastButton />
                                </div>
                            </Col>
                            <Form.Group as={Row} >
                                <Form.Label className="text-center mr-10 mt-1">Search :</Form.Label>
                                <Col>
                                    <Form.Control type="text" size='sm' placeholder='Search...' onChange={(e) => this.handleChangeSearchInput(e)} />
                                </Col>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Table className="table-bordered table-hover custom-table-list table-responsive-lg" size="sm">
                        <thead className="">
                            <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                <th style={{ width: '5%' }}>No</th>
                                <th style={{ width: '25%' }}>API Name</th>
                                <th style={{ width: '15%' }}>Message</th>
                                <th style={{ width: '15%' }}>Output Message</th>
                                <th style={{ width: '5%' }}>Level</th>
                                <th style={{ width: '10%' }}>Time Stamp</th>
                                <th style={{ width: '10%' }}>Create By</th>
                                <th style={{ width: '15%' }}>Exception</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!GetAPI.isLoadLogger ? <tr><td colSpan={8} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                            {GetAPI.isLoadLogger && this.state.formData.length == 0 ? <tr><td colSpan={8} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                            {this.state.formData.length != 0 && this.state.formData.map((item: GetLogModel.LoggerModel, idx: any) => {
                                return (
                                    <tr key={idx}>
                                        <td className="text-center">{this.hilightText(item.id)}</td>
                                        <td>{this.hilightText(item.function_name)}</td>
                                        <td>
                                            <InputGroup>
                                                <FormControl as="textarea" aria-label="With textarea" value={item.message} readOnly ></FormControl>
                                                <Button variant="outline-secondary" size="sm" onClick={() => { navigator.clipboard.writeText(item.message) }} ><FaCopy /></Button>
                                                <Button variant="outline-secondary" size="sm" onClick={() => this.handleCloseMessageModal(idx, 'message')}><FaEnvelopeOpenText /></Button>
                                            </InputGroup>
                                        </td>
                                        <td>
                                            <InputGroup>
                                                <FormControl as="textarea" aria-label="With textarea" value={item.output_message} readOnly ></FormControl>
                                                <Button variant="outline-secondary" size="sm" onClick={() => { navigator.clipboard.writeText(item.output_message) }} ><FaCopy /></Button>
                                                <Button variant="outline-secondary" size="sm" onClick={() => this.handleCloseMessageModal(idx, 'output_message')}><FaEnvelopeOpenText /></Button>
                                            </InputGroup>
                                        </td>
                                        <td className="text-center" style={{ color: findColurLevel(item.level) }}>{this.hilightText(item.level)}</td>
                                        <td className="text-center">{this.hilightText(formatRFC3339TpDMY(String(item.timestamp)))}</td>
                                        <td className="text-center">{this.hilightText((item.created_by))}</td>
                                        <td>{item.exception}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Row>
                {/*Modal Message Error*/}
                <Modal show={this.state.isShowMessageModal} onHide={() => this.handleCloseMessageModal(null, '')} size="lg" backdrop="static" keyboard={false}>
                    <Modal.Header translate closeButton>
                        <Modal.Title>
                            {GetAPI.statusLogger == 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] != null && this.state.selectMessageID != null &&
                                GetAPI.responseLogger['result_list'][this.state.selectMessageID].function_name
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                {GetAPI.statusLogger == 200 && GetAPI.isLoadLogger && GetAPI.responseLogger['result_list'] != null && this.state.selectMessageID != null &&
                                    <FormControl as="textarea" rows={5} aria-label="With textarea" value={GetAPI.responseLogger['result_list'][this.state.selectMessageID][this.state.messageMode]} readOnly />
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.handleCloseMessageModal(null, '')}>
                            <FaTimesCircle /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.Logger }), ({ ...GetLogStore.actionCreators }))(LogTable)