import { Action, Reducer, createStore } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/callbackconfig/CreateCallback'

interface CreateCallback {
    type: 'CREATECALLBACK'
    response: Models.CallbackResponse
    status: Number,
    statusMessage: String
}
interface FailCreateCreateCallback {
    type: 'CREATECALLBACKFAIL'
    status: number,
    statusMessage: any
}
interface ClearCreateCallback {
    type: 'CLEARCREATECALLBACK'
}

type KnownAction = CreateCallback | FailCreateCreateCallback | ClearCreateCallback

export const actionCreators = {
    requestCreateCallback: (p: boolean, method: String, formData: Models.CallbackReq): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log('call actionCreators : requestCreateCallback')
        const appState = getState();
        if (appState && appState.createCallback && p && method == 'POST') {
            console.log(formData)
            await fetch(`/v1/Callbackconfig/Create`, {
                method: 'POST',
                headers: {

                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('LOG_APPLICATION'),
                },
                body: JSON.stringify({
                    result_list: formData
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    }
                    return response.json() as Promise<Models.CallbackResponse>
                })
                .then((data) => {
                    console.log('dispatch : CREATECALLBACK')
                    dispatch({ type: 'CREATECALLBACK', response: data, status: 200, statusMessage: '' });
                })
                .catch((error) => {
                    console.log('dispatch : CREATECALLBACKFAIL')
                    dispatch({ type: 'CREATECALLBACKFAIL', status: 204, statusMessage: error });
                });
        } else if (method == 'CLEAR') {
            console.log('dispatch : CLEARCREATECALLBACK')
            dispatch({ type: 'CLEARCREATECALLBACK'});
        }
    }
}

const unloadedState: Models.SaveCallbackState = { SaveCallbackisLoad: false };

export const reducer: Reducer<Models.SaveCallbackState> = (state: Models.SaveCallbackState | undefined, incomingAction: Action): Models.SaveCallbackState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CREATECALLBACK':
            return {
                SaveCallbackisLoad: true,
                SaveCallbackresponse: action.response,
                SaveCallbackstatus: action.status

            }
        case 'CREATECALLBACKFAIL':
            return {
                SaveCallbackisLoad: true,
                SaveCallbackmessage: action.statusMessage,
                SaveCallbackstatus: action.status
            }
        case 'CLEARCREATECALLBACK':
            return {
                SaveCallbackisLoad: false,
                SaveCallbackmessage: "",
                SaveCallbackstatus: 400,
            }
        default: return state;
    }
}