import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/Logger'

// GET
interface LoadLogger {
    type: 'LOAD_LOGGER'
}
interface LoadedLogger {
    type: 'LOADEDLOGGER'
    response: Models.ResponseLogger
    status: number,
    statusMessage: string
}
interface FailLoadedLogger {
    type: 'LOADLOGGERFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedLogger {
    type: 'CLEARLOADEDLOGGER'
}



// Search
interface SearchLogger {
    type: 'SEARCH_LOGGER'
}
interface SearchedLogger {
    type: 'SEARCHLOGGER'
    response: Models.ResponseLogger
    status: number,
    statusMessage: string
}
interface FailSearchdLogger {
    type: 'SEARCHLOGGERFAIL'
    status: number,
    statusMessage: any
}
interface ClearSearchLogger {
    type: 'CLEARSEARCHLOGGER'
}

type KnownAction = LoadedLogger | FailLoadedLogger | LoadLogger | ClearLoadedLogger | SearchLogger | SearchedLogger | FailSearchdLogger | ClearSearchLogger

export const actionCreators = {
    requestGetLogger: (check: boolean, method: string, function_name: string, message: string, output_message: string, created_by: string, timestamp: string, page: string, page_size: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && check && method == 'GET') {
            console.log('call actionCreators : requestGetLogger')
            fetch(`/v1/logger?function_name=` + function_name + "&message=" + message + "&output_message=" + output_message + "&created_by=" + created_by + "&timestamp=" + timestamp + '&page=' + page + '&page_size=' + page_size, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LOG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    /*                    dispatch({ type: 'LOADEDLogger', response: data, status: 200, statusMessage: '' });*/
                    //dispatch({ type: 'LOADLoggerFAIL', status: 204, statusMessage: error.message });
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADLOGGERFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDLOGGER', response: data, status: 200, statusMessage: '' });
                        })
                    }
                });
            dispatch({ type: 'LOAD_LOGGER' });
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADEDLOGGER' })
        }
    },

    requestSearchLogger: (check: boolean, method: string, function_name: string, message: string, output_message: string, created_by: string, timestamp: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && check && method == 'GET') {
            console.log('call actionCreators : requestSearchLogger')
            fetch(`/v1/logger?function_name=` + function_name + "&message=" + message + "&output_message=" + output_message + "&created_by=" + created_by + "&timestamp=" + timestamp , {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LOG_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'SEARCHLOGGERFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'SEARCHLOGGER', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            //dispatch({ type: 'SEARCHLogger', response: data, status: 200, statusMessage: '' });
            //dispatch({ type: 'SEARCHLoggerFAIL', status: 204, statusMessage: error.message });
            dispatch({ type: 'SEARCH_LOGGER' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARSEARCHLOGGER' });
        }
    },
}

const unloadedState: Models.GetLoggerState = { isLoadLogger: false };
const unloadedSearchState: Models.SearchLoggerState = { isLoadSearchLogger: false };

const reducerGet: Reducer<Models.GetLoggerState> = (state: Models.GetLoggerState | undefined, incomingAction: Action): Models.GetLoggerState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_LOGGER':
            return {
                isLoadLogger: false,
            }

        case 'LOADEDLOGGER':
            return {
                isLoadLogger: true,
                responseLogger: action.response as any,
                statusLogger: action.status
            }

        case 'LOADLOGGERFAIL':
            return {
                isLoadLogger: true,
                messageLogger: action.statusMessage,
                statusLogger: action.status
            }
        case 'CLEARLOADEDLOGGER':
            return {
                isLoadLogger: false,
                messageLogger: '',
                statusLogger: 0
            }
        default: return state;
    }
}

const reducerSearch: Reducer<Models.SearchLoggerState> = (state: Models.SearchLoggerState | undefined, incomingAction: Action): Models.SearchLoggerState => {
    if (state == undefined) {
        return unloadedSearchState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SEARCH_LOGGER':
            return {
                isLoadSearchLogger: false,
            }

        case 'SEARCHLOGGER':
            return {
                isLoadSearchLogger: true,
                responseSearchLogger: action.response as any,
                statusSearchLogger: action.status
            }

        case 'SEARCHLOGGERFAIL':
            return {
                isLoadSearchLogger: true,
                messageSearchLogger: action.statusMessage,
                statusSearchLogger: action.status
            }

        case 'CLEARSEARCHLOGGER':
            return {
                isLoadSearchLogger: false,
                messageSearchLogger: "",
                statusSearchLogger: 400
            }

        default: return state;
    }
}

export const rootReducer = combineReducers({ GetAPI: reducerGet, SearchAPI: reducerSearch });