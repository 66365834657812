import { Action, Reducer, createStore } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/callbackconfig/CallbackMaster'

interface LoadCallbackconfig{
    type: 'LOADCALLBACKCONFIG'
    response: Models.CallbackResponse
    status: Number,
    statusMessage: String
}
interface FailCallbackconfig {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}
type KnownAction = LoadCallbackconfig | FailCallbackconfig

export const actionCreators = {
    requestCallbackMaster : (p: boolean, method: String): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestPositionemmethodployee')
        if (appState && appState.callbackConfig && p && method == 'POST') {
            await fetch(`/v1/Callbackconfig/Get`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LOG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    }
                    return response.json() as Promise<Models.CallbackResponse>
                })
                .then(data => {
                    console.log('dispatch : LOADCALLBACKCONFIG')
                    dispatch({ type: 'LOADCALLBACKCONFIG', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    console.log('dispatch : LOADFAIL')
                    dispatch({ type: 'LOADFAIL', status: 204, statusMessage: error });
                });
        }
    }
}

const unloadedState: Models.CallbackMasterState = { CallbackisLoad: false };

export const reducer: Reducer<Models.CallbackMasterState> = (state: Models.CallbackMasterState | undefined, incomingAction: Action): Models.CallbackMasterState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADCALLBACKCONFIG':
            return {
                CallbackisLoad: true,
                Callbackresponse: action.response,
                Callbackstatus: action.status
            }

        case 'LOADFAIL':
            return {
                CallbackisLoad: true,
                Callbackmessage: action.statusMessage,
                Callbackstatus: action.status
            }
        default: return state;
    }
}