import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { Route, Switch } from 'react-router';
import Home from '../components/Home';
import Logger from '../components/log/Logger';
import SubLayout from './SubLayout';
import { PrivateRoute } from './PrivateRoute';
import AddConfigMaster from './config/AddConfigMaster';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <Switch>
            <SubLayout>
                <Route exact path='/' component={Home} />
                <PrivateRoute path='/log' component={Logger} />
                <PrivateRoute path='/addconfig' component={AddConfigMaster} />
            </SubLayout>
        </Switch>
    </React.Fragment>
);
