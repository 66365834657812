import * as LoggerStore from './log/Logger'
import * as LoggerModels from '../models/Logger'

import * as CallbackConfigMaster from './callbackconfig/CallbackMaster'
import * as ModelsCallbackConfigMaster from '../models/callbackconfig/CallbackMaster'

import * as CallbackTokens from './callbackconfig/CallbackTokens'
import * as ModelsCallbackTokens from '../models/callbackconfig/CallbackTokens'

import * as CreateCallback from './callbackconfig/CreateCallback'
import * as ModelsCreateCallback from '../models/callbackconfig/CreateCallback'

// The top-level state object
export interface ApplicationState {
    Logger: LoggerModels.GetLoggerState | undefined;
    callbackConfig: ModelsCallbackConfigMaster.CallbackMasterState | undefined;
    callbackTokens: ModelsCallbackTokens.CallbackTokenState | undefined;
    createCallback: ModelsCreateCallback.SaveCallbackState | undefined;
    //counter: Counter.CounterState | undefined;
    //weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    Logger: LoggerStore.rootReducer,
    callbackConfig: CallbackConfigMaster.reducer,
    callbackTokens: CallbackTokens.reducer,
    createCallback: CreateCallback.reducer,
    //counter: Counter.reducer,
    //weatherForecasts: WeatherForecasts.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
