
function toTimestamp(strDate: any) {
    var datum = Date.parse(strDate);
    return datum / 1000;
}

export function formatDMY(dmy: string) {

    if (dmy != undefined && dmy != "" && dmy != null) {

        var dateUTC = ""

        dateUTC = dmy.split('.').join('/')
        var year = parseInt(dmy.substring(6, 10));
        var month = parseInt(dmy.substring(3, 5));
        var day = parseInt(dmy.substring(0, 2));
        var hour = parseInt(dmy.substring(11, 13));
        var m = parseInt(dmy.substring(14, 16));
        var s = parseInt(dmy.substring(17, 19));

        dateUTC = month + '/' + day + '/' + year + ' ' + hour + ':' + m + ':' + s

        var UTC = new Date()
        let localTime = toTimestamp(dateUTC).valueOf() * 1000
        let localOffset = UTC.getTimezoneOffset() * 60000;
        let utc = localTime + localOffset;
        let thai = utc + (3600000 * 14);
        let dateUTCUse = new Date(thai);
        let dateUTCUseString = dateUTCUse.toLocaleString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
        var datevalue = "";
        datevalue = dateUTCUseString.split('/').join('-')

        return datevalue.split(',').join('  ');

    } else {
        datevalue = "";

        return datevalue;
    }
}

export function formatYMDToDMY(dmy: string) {
    if (dmy != undefined && dmy != "" && dmy != 'null') {
        var year = dmy.substring(0, 4);
        var month = dmy.substring(4, 6);
        var day = dmy.substring(6, 8);
        var Datevalue = "";
        var datevalue = "";
        var UTC = new Date()
        if (dmy.length < 9) {
            datevalue = day + '-' + month + '-' + year

        } else {
            var hour = dmy.substring(8, 10);
            var m = dmy.substring(10, 12);
            var s = dmy.substring(12, 14);
            if (s != "") {
                datevalue = day + '-' + month + '-' + year + ' ' + hour + ':' + m + ':' + s
            } else {
                datevalue = day + '-' + month + '-' + year + ' ' + hour + ':' + m
            }
            /*let localTime = toTimestamp(Datevalue).valueOf() * 1000
            let localOffset = UTC.getTimezoneOffset() * 60000;
            let utc = localTime + localOffset;
            let thai = utc + (3600000 * 14);
            let dateUTCUse = new Date(thai);
            let dateUTCUseString = dateUTCUse.toLocaleString()
            
            datevalue = dateUTCUseString.split('/').join('-')
            datevalue = datevalue.split(',').join('  ');*/
        }
        return datevalue;
    } else {
        Datevalue = "";

        return Datevalue;
    }
}

export function taskStatusColour(status: any) {
    if (status != "") {
        switch (status) {
            case 'FINISHED':
                return 'task-success';
            case 'ERROR':
                return 'task-error';
            case 'WARNING':
                return 'task-warning';
            default:
                return '';
        }
    }
}

export function fetchAsync(id: any) {
    //const response = await fetch(`/v1/test/fapp/connection?apiUrl=` + id, {
    //    method: 'GET',
    //    headers: {
    //        'Authorization': 'Bearer ' + localStorage.getItem('LG_APPLICATION'),
    //    },
    //});
    ////var value: any = await response.json() as any;
    //if (await response.ok) {
    //    return 200
    //} else {
    //    return null;
    //}
    return id;
}

export function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function submassage(message: string, size: number) {
    var value = "";
    if (message != null) {
        value = message.substr(0, size) + '...';
    }
    return value
}

export function getBase64(file: any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    //reader.onload = function () {
    //    cb(reader.result)
    //};
    //reader.onerror = function (error) {
    //    console.log('Error: ', error);
    //};
    return reader
}

export function formatRFC3339TpDMY(dmy: string) {
    if (dmy != undefined && dmy != "" && dmy != 'null') {
        var year = dmy.substring(0, 4);
        var day = dmy.substring(8, 10);
        var month = dmy.substring(5, 7);
        var Datevalue = "";
        var datevalue = "";
        var UTC = new Date()
        if (dmy.length < 9) {
            datevalue = day + '-' + month + '-' + year

        } else {
            var times = dmy.substring(11, 19);
            datevalue = day + '-' + month + '-' + year + ' ' + times;
        }
        return datevalue;
    } else {
        Datevalue = "";

        return Datevalue;
    }
}

export function findColurLevel(key: string) {
    var value : string = ""
    if (key == "Error") {
        value = "red"
    } else if (key == "Info") {
        value = "blue"
    } else if (key == "Warning") {
        value = "orange"
    } else {
        value = "black"
    }
    return value
}
