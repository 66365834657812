import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/callbackconfig/CallbackTokens'

interface LoadCallbackToken {
    type: 'LOADCALLBACKTOKENS'
    response: Models.TokensResponse
    status: Number,
    statusMessage: String
    request: Models.RequestTokens
}
interface FailCallbackTOken {
    type: 'LOADCALLBACKTOKENFAIL'
    status: number,
    statusMessage: any
}

interface ClearCallbackTOken {
    type: 'CLEARCALLBACKTOKEN'
    status: number,
    statusMessage: any
    request: Models.RequestTokens
}

type KnownAction = LoadCallbackToken | FailCallbackTOken | ClearCallbackTOken

export const actionCreators = {
    requestCallbackToken: (p: boolean, method: String, id: string, idx: number, system_id: string, sub_system_id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestPositionemmethodployee')
        if (appState && appState.callbackTokens && p && method == 'POST') {
            await fetch(`/v1/Callbackconfig/Token?id=` + id + '&system_id=' + system_id + '&sub_system_id=' + sub_system_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('LOG_APPLICATION'),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.json() as any)
                    }
                    return response.json() as Promise<Models.TokensResponse>
                })
                .then(data => {
                    console.log('dispatch : LOADCALLBACKTOKENS')
                    dispatch({ type: 'LOADCALLBACKTOKENS', response: data, status: 200, statusMessage: '', request: { id : id , idx : idx} });
                })
                .catch(error => {
                    console.log('dispatch : LOADFAIL')
                    dispatch({ type: 'LOADCALLBACKTOKENFAIL', status: 204, statusMessage: error });
                });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARCALLBACKTOKEN', status: 400, statusMessage: "", request: { id: "", idx: 0} });
        }
    }
}

const unloadedState: Models.CallbackTokenState = { CallbackTokenisLoad: false };

export const reducer: Reducer<Models.CallbackTokenState> = (state: Models.CallbackTokenState | undefined, incomingAction: Action): Models.CallbackTokenState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADCALLBACKTOKENS':
            return {
                CallbackTokenisLoad: true,
                CallbackTokenresponse: action.response,
                CallbackTokenstatus: action.status,
                CallbackTokenrequest: action.request
            }

        case 'LOADCALLBACKTOKENFAIL':
            return {
                CallbackTokenisLoad: true,
                CallbackTokenmessage: action.statusMessage,
                CallbackTokenstatus: action.status
            }
        case 'CLEARCALLBACKTOKEN':
            return {
                CallbackTokenisLoad: false,
                CallbackTokenmessage: "",
                CallbackTokenstatus: 400,
                CallbackTokenrequest: action.request
            }
        default: return state;
    }
}