import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import Swal from 'sweetalert2'
//import PullJobConfigTable from './PullJobConfigTable'
import * as GetLogModel from '../../models/Logger'
import { FaSearch, FaCheckCircle, FaTimesCircle, FaPlayCircle, FaFolderOpen, FaAngleDown, FaPlusCircle, FaFileExport } from "react-icons/fa";

import LogTable from './LogTable'

class Logger extends React.Component<any, { key: string, SearchLoggerReq: GetLogModel.SearchLoggerReq }>{

    constructor(props: any) {
        super(props);
        this.state = {
            key: "",
            SearchLoggerReq: {
                function_name: '',
                message: '',
                output_message: '',
                created_by: '',
                timestamp: '',
            },
        }
        this.callbackReset = this.callbackReset.bind(this)
    }

    handleChangeSearchInput = (e: any, key: string) => {
        if (key != "") {
            var data = this.state.SearchLoggerReq
            data[key] = e.target.value
            this.setState({
                SearchLoggerReq: data
            })
        }
    }

    public callbackReset() {
        this.setState({
            key: ''
        })
    }

    Reset = () => {
        console.log('Reset function')
        this.setState({
            key: 'Reset'
        })
    }

    handleClearInput = (key: string) => {
        console.log(key)
        if (key != "") {
            var data = JSON.parse(JSON.stringify(this.state.SearchLoggerReq))
            data[key] = ""
            this.setState({
                SearchLoggerReq: data
            })
        }
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div className='trapezoid-head' style={{ width: '200px' }}>
                                <span>
                                    FocusOne API Log
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="container-fluid">
                                                <Accordion defaultActiveKey="1">
                                                    <Accordion.Toggle className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }} eventKey="0" as='a'>
                                                        <span>
                                                            <i className="fa fa-search" aria-hidden="true"></i>
                                                            Search menu
                                                            <div style={{ display: 'inline-block', float: 'right' }}>
                                                                <FaAngleDown />
                                                            </div>
                                                        </span>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0">
                                                        <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                                            <Row>
                                                                <Col lg="6">
                                                                    <Form className="mb-3" as={Row} >
                                                                        <Form.Label column sm="3" className="text-center">API Name :</Form.Label>
                                                                        <Col sm="9">
                                                                            <InputGroup>
                                                                                <Form.Control type="text" size='sm' value={this.state.SearchLoggerReq.function_name} onChange={(e) => this.handleChangeSearchInput(e, 'function_name')} />
                                                                                <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('function_name')}><FaTimesCircle /></Button>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form >
                                                                </Col>
                                                                <Col lg="6">
                                                                    <Form className="mb-3" as={Row} >
                                                                        <Form.Label column sm="3" className="text-center">Message :</Form.Label>
                                                                        <Col sm="9">
                                                                            <InputGroup>
                                                                                <Form.Control type="text" size='sm' value={this.state.SearchLoggerReq.message} onChange={(e) => this.handleChangeSearchInput(e, 'message')} />
                                                                                <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('message')}><FaTimesCircle /></Button>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form >
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <Form className="mb-3" as={Row} >
                                                                        <Form.Label column sm="3" className="text-center">Output Message :</Form.Label>
                                                                        <Col sm="9">
                                                                            <InputGroup>
                                                                                <Form.Control type="text" size='sm' value={this.state.SearchLoggerReq.output_message} onChange={(e) => this.handleChangeSearchInput(e, 'output_message')} />
                                                                                <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('output_message')}><FaTimesCircle /></Button>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form >
                                                                </Col>
                                                                <Col lg="6">
                                                                    <Form className="mb-3" as={Row} >
                                                                        <Form.Label column sm="3" className="text-center">Create By :</Form.Label>
                                                                        <Col sm="9">
                                                                            <InputGroup>
                                                                                <Form.Control type="text" size='sm' value={this.state.SearchLoggerReq.created_by} onChange={(e) => this.handleChangeSearchInput(e, 'created_by')} />
                                                                                <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('created_by')}><FaTimesCircle /></Button>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form >
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <Form className="mb-3" as={Row} >
                                                                        <Form.Label column sm="3" className="text-center">Time Stamp :</Form.Label>
                                                                        <Col sm="9">
                                                                            <InputGroup>
                                                                                <Form.Control type="date" size='sm' value={this.state.SearchLoggerReq.timestamp} onChange={(e) => this.handleChangeSearchInput(e, 'timestamp')} />
                                                                                <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('timestamp')}><FaTimesCircle /></Button>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Form >
                                                                </Col>

                                                            </Row>
                                                            <hr />
                                                            <Row>
                                                                <Col>
                                                                    <Button variant="info" size="sm" onClick={this.Reset}><FaSearch /> ค้นหา</Button>
                                                                    <Button variant="primary" className="mx-2" size="sm"><FaFileExport /> Export</Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Accordion.Collapse >
                                                </Accordion>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <LogTable checkkey={this.state.key} callbackkey={this.callbackReset.bind(this)} searchLoggerReq={this.state.SearchLoggerReq} />
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

}
export default Logger