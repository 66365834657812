import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

var imgFile = require('../image/f1-new-logo.png');

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <div className="navbar-header">
                        <div className="navbar-brand" style={{ padding: '0', width: '700px' }}>
                            <div className="nav-brand-name" >
                                <Row>
                                    <div className="trapezoid-logo mx-2" style={{ color: '#adb5bd', display: 'inline-block' }}>
                                        {/*<img src={logo} style={{ width: '96px', height: '40px', cursor: 'pointer', marginTop: '4px;}} >*/}
                                        <a href={'/'}>
                                            {/*< img src={'https://slsn-o2c-uat.thailanderp.com/images/f1-new-logo.png'} style={{ marginTop: '4px', width: '96px', height: '40px' }} alt="file" />*/}
                                            < img src={imgFile} style={{ marginTop: '4px', width: '96px', height: '40px' }} alt="file" />
                                        </a>
                                    </div>
                                    <div className="mx-4 mt-3">
                                        FocusOne Log
                                        <span style={{ fontSize: '8pt', paddingLeft: '5px' }}>V 1.0.0 </span>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse font-weight-bold" isOpen={this.state.isOpen} navbar>
                        <ul className="navbar-nav flex-grow">
                            {/*                            <DropdownButton id="dropdown-basic-button" title={<FaUserCircle />} variant="outline-dark" size="sm">
                                <Dropdown.Item><Link to="/Login">Log In</Link></Dropdown.Item>
                            </DropdownButton>*/}
                            <NavItem>
                                <NavLink tag={Link} className="text-dark font-navbar mr-2" to="/log" >API Log</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark font-navbar mr-3" to="/addconfig" >Token</NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
